@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Protest+Guerrilla&display=swap');
a {
    color: #e18a3d;
}
body {
    font-family: 'Lato', sans-serif;
}
.thin-wrapper {
    display: flex;
    max-width: 990px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    height: 100vh;
    padding: 0 15px;
}

.inner {
    display: flex;
    flex-direction: column;
    padding: 1em;
    box-shadow: 0 1.4px 10px rgba(0, 0, 0, 0.045),
        0 11px 80px rgba(0, 0, 0, 0.09);
    border-radius: 25px;
}

.logo {
    display: inline-block;
    text-align: center;
}

.logo img {
    max-width: 150px;
}

.title {
    text-align: center;
    font-size: 16px;
    color: #240a51;
    margin-top: 1em;
}

.lost-paswd {
    font-size: 14px;
}

.form-login {
    max-width: 400px;
    margin: 0 auto;
}
.form-login table {
}

button {
}
button.full-width {
    width: 100%;
}
button.primary:hover {
    opacity: 0.8;
    background: #391d6b;
    transition: all 200ms;
}

button.primary {
    background: #240a51;
    color: #fff;
    margin-bottom: 1em;
}
button.outline {
    background: none;
    color: #240a51;
    border-color: #240a51;
}
button.outline:hover {
    background: #eee;
    border-color: #240a51;
}

.dash-card {
    background: #fff;
    box-shadow: 0 3.6px 7.9px rgba(0, 0, 0, 0.035),
        0 29px 63px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    padding: 4% 2%;
    color: #e18a3d;
    cursor: pointer;
    text-decoration: none;
}
.dash-card span,
.dash-card svg,
.dash-card img {
    color: #e2e2e2;
    position: relative;
}
.dash-card span:before {
    content: '';
    width: 50px;
    height: 5px;
    position: absolute;
    background: #d4731c;
    border-radius: 100px;
    bottom: 0;
}
.dash-card.centred span:before {
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dash-card:hover span:before {
    background: #fff;
}

.dash-card-disabled {
    background: #e2e3e5;
    pointer-events: none;
    cursor: default;
}
.dash-card-disabled svg {
    color: #d4731c;
    position: relative;
}

.left-menu svg {
    color: #d4731c;
}
.left-menu a:hover {
    background: #d4731c;
    color: #fff;
}
.left-menu a:hover svg {
    color: #fff;
}

.MuiPaper-elevation1 {
    box-shadow: 16px 11px 10px 0px rgb(223 214 214 / 20%),
        2px 2px 14px 8px rgb(236 236 236 / 14%),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.dash-card:hover {
    background: #d4731c;
    transition: all 200ms;
}
.dash-card:hover span {
    color: #fff !important;
}

.top-admin-bar {
    box-shadow: none !important;
    border-bottom: 1px solid #240a5117;
}
.top-admin-bar button {
    color: #240a51;
}
.top-admin-bar h6 {
    font-weight: 300;
    color: #b1b1b1;
    font-size: 1rem;
}

.MuiPaper-elevation1 {
    box-shadow: none;
}

.top-admin-bar {
    background: #fff;
}
.spacer {
    flex: 1;
}

.t-jumbotron {
    background: #f7f7f7;
}
.t-jumbotron.blue {
    background: #f8fbff;
}

.big-text {
    font-size: 2em;
}

.sumup {
    background: #d4731c;
    font-size: 1.2em;

    color: #fff !important;
}
.sumup input {
    color: #fff !important;
    font-size: 1.2em;
}

.auto-w {
    width: auto;
}

.login-panel .MuiPaper-elevation1 {
    box-shadow: none !important;
    width: 100%;
}

.autocompleteinput input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-sizing: inherit;
}

.autocompleteinput div {
    margin-top: -8px;
    margin-bottom: -8px;
}
.invoices-list-tab {
    flex-wrap: wrap;
}
.invoices-list-tab table td,
.invoices-list-tab table th {
    border-top: 0;
    font-size: 1.3em;
    font-weight: 300;
}

.invoices-list-tab table th {
    position: relative;
}
.invoices-list-tab table th:before {
    content: '';
    width: 50px;
    height: 5px;
    position: absolute;
    background: #d4731c;
    border-radius: 100px;
    bottom: 0;
}

.invoices-list-tab td {
    font-size: 1.3em;
    font-weight: 300;
}
.invoices-list-tab .number {
    padding: 1.1em;
    border-radius: 100px;
    font-weight: 400;
    font-size: 90%;
}

.MuiCircularProgress-svg {
    color: #240a51;
}

.InfoPopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    border: 4px solid #e2e3e5;
}

.InfoPopupClose {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10000;
}

.InfoPopupBg {
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 990;
    width: 100%;
    height: 100%;
}

.custom-file-label::after {
    content: 'Przeglądaj';
}
.form-control-select {
    color: #7a7a7a !important;
}
.contact-form-left {
    /* height: 388px; */
    left: 0;
    top: 90px;
    width: calc(100% - 358px);
}
.contact-form-left h3 {
    font: 400 28px/1.2em 'Lato';
}
.contact-form-in {
    font: 400 16px 'Lato';
}
.contact-form-in input[type='text'],
.contact-form-in input[type='email'] {
    border: 0;
    border-bottom: 2px solid #fff;
    height: 40px;
    width: 100%;
    color: #7a7a7a;
}
.contact-form-in input[type='text']::placeholder,
.contact-form-in input[type='email']::placeholder,
.contact-form-in textarea::placeholder {
    color:  #7a7a7a;
}
.contact-form-in input[type='text']:-ms-input-placeholder,
.contact-form-in input[type='email']:-ms-input-placeholder,
.contact-form-in textarea:-ms-input-placeholder {
    color:  #7a7a7a;
}
.contact-form-in input[type='text']::-webkit-input-placeholder,
.contact-form-in input[type='email']::-webkit-input-placeholder,
.contact-form-in textarea::-webkit-input-placeholder {
    color:  #7a7a7a;
}
.contact-form-in .form-control:focus {
    outline: none;
    color:  #7a7a7a;
    border-color:  #fff;
}
.contact-form-in textarea {
    border: 0;
    border-bottom: 2px solid #fff;
    height: 40px;
    color:  #7a7a7a;
    width: 100%;
    resize: none;
}
.contact-form-in input[type='submit'] {
    border: 0;
    height: 40px;
}
.contact-form-right {
    left: 50px;
    top: 0;
}
.bg-orange,
.btn-add-to-cart {
    background-color: #e18a3d;
}

.MuiTablePagination-displayedRows {
    margin-top: 1em;
    margin-bottom: 1em;
}
.MuiTablePagination-selectLabel {
    margin-top: 1em;
    margin-bottom: 1em;
}
.swiper.mySwiper {
    width:780px;
    height:350px;
    }
.swiper.mySwiper  .css-rccht4{
    text-align: center;
        margin: 0 auto;
    }
.swiper.mySwiper  .swiper-slide{
    display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    
    .swiper.mySwiper  .swiper-slide .slide-heading {
    color: #ffffff;
        font-weight: 300;}
    .swiper.mySwiper  .swiper-slide .slide-span {
        display: block;
        font-weight: 600;
        color: #fff;
        font-size: 3rem;}
    .swiper.mySwiper  .swiper-slide h3 span{
    display: block;
        font-weight: 600;
        color: #4F4271;
        font-size: 3rem;}
    .swiper.mySwiper  .swiper-slide .content-slide:first-of-type{
    border-bottom: 1px solid #ccc;
        background: #F9F9F9;
        border-radius: 10px 10px 0 0;
        padding: 15px 0;
        margin:  0 18px;
        text-align: center;
    }
    .swiper.mySwiper  .swiper-slide .content-slide:last-of-type{
    background: rgb(36, 10, 81);
        border-radius: 0 0 10px 10px;
        padding: 15px 0;
        margin:   0 18px;
        text-align: center;
    }
    .TextLogging {
        color: #282D64;
        font: 'Lato';
        font-size: 1.5rem;
        padding: 0%;
        margin: 0%;
    }
    .TextLoggingOrange{
        color: #FF7C34;
        font: 'Lato';
        font-size: 1.5rem;
        padding: 0%;
        margin: 0%;
    }

    .bottomTextLogin{
        font: 'Open Sans';
        color:#4E89C8;
        font-size: 1.3rem;
    } 
    .image-row {
        display: flex;
        justify-content:center;
        align-items: center;
        gap:60px; 
    }
    
    .image-row img {
        width: 100px; 
        height: auto; 
        margin: 20px;
    }
    
    
    .logexpl{
        color:#4E89C8;
        font: 'Lato';
        font-size: 1rem;
        padding: 0%;
        margin: 0%;  
    }

    .divider {
        display: flex;
        align-items: center;
        text-align: center;
        margin: 20px 0; 
    }
    
    .divider::before,
    .divider::after {
        content: "";
        flex: 1;
        border-bottom: 1px solid #ccc; 
        margin: 0 10px; 
    }
    
    .divider span {
        font-size: 14px; 
        color: #999; 
    }

    .inputLog label + div {
        border-radius: 30px !important;
    }

   

    .loginName label + div {
        border-radius: 30px !important;
        border-color: #7db3b8 !important;
    }
    .custom-outline {
        border-radius: 30px; 
        border-color: #57A0CE !important; 
    }
    
    .MuiInputBase-input {
        padding: 16px; 
    }
      
     .MuiInputAdornment-root {
        color: #FF7C34; 
    }
    /*  */
    .custom-blue-label .MuiFormLabel-root {
        color: #57A0CE !important; 
    }

    .custom-blue-label .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #57A0CE !important;
    }

    /*  */

    .MuiInputAdornment-root {
        color: #57A0CE; 
    }
    .container {
        margin-top: 60px;
        display: flex;
        align-items: center; 
    }
    
    .left-content, .right-content {
        flex: 1;
        padding: 20px;
    }
    
    .vertical-line {
        width: 1px;
        background-color: orange;
        height: 100%;
        position: relative;
    }
    
    .vertical-line:after {
        content: '';
        width: 6px;
        height: 6px;
        background-color: orange;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    
  .footer-title {
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color:#241753;
}


.image-row {
    display: flex;
    justify-content: center;
    gap: 10px; 
    margin-bottom: 20px;
}

.footerImg {
    width: 50px;
    height: auto;
}


.bottom-text-wrapper {
    text-align: center;
    margin-top: auto; 
}

.bottomTextLogin {
    font-size: 14px;
    color: #4E89C8;
    font-family: 'Open Sans';
} 