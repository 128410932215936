.menuElementContainer {
  display: flex;
  width: 90%;
  min-width: 100px;
  min-height: 100px;
  max-height: 100px;
  margin: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  text-decoration: none;
}

.boxStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  margin: 10px 10px;
  width: 30em;
}

.fontsStyle  {
  padding: 2%;
  color: #d4731c !important;
  text-transform: normal;
  text-align: center;
  font-size: 1rem;
}

