body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.newsletter-fix h4 {
    display: none;
}
.newsletter-fix label {
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 15px #ccc;
    border-bottom: 4px solid #d4731c;
}

.newsletter-fix input[type='checkbox'] {
    margin-right: 10px;
}

#support-widget {
    animation: animate 30s linear infinite;
}

@keyframes animate {
    0% {
        box-shadow: 0 0 0 0 rgb(212, 115, 28, 0.7), 0 0 0 0 rgb(212, 115, 28, 0.7);
    }

    1% {
        box-shadow: 0 0 0 25px rgb(212, 115, 28, 0), 0 0 0 0 rgb(212, 115, 28, 0.7);
    }

    4% {
        box-shadow: 0 0 0 25px rgb(212, 115, 28, 0), 0 0 0 15px rgb(212, 115, 28, 0);
    }

    5% {
        box-shadow: 0 0 0 0 rgb(212, 115, 28, 0), 0 0 0 15px rgb(212, 115, 28, 0);
    }
}

.product-modal-wysiwig {
    display: flex;
    flex-wrap: wrap;
}

.product-modal-wysiwig img {
    max-width: 100%;
}

.product-modal-wysiwig h1,
.product-modal-wysiwig h2,
.product-modal-wysiwig h3,
.product-modal-wysiwig h4,
.product-modal-wysiwig h5,
.product-modal-wysiwig h6 {
    margin-bottom: 25px;
    clear: both;
}

.product-modal-wysiwig h1,
.product-modal-wysiwig h1 * {
    font: 300 56px/1.2em 'Lato', sans-serif !important;
}

.product-modal-wysiwig h2,
.product-modal-wysiwig h2 * {
    font: 300 48px/1.2em 'Lato', sans-serif !important;
}

.product-modal-wysiwig h3,
.product-modal-wysiwig h3 * {
    font: 300 40px/1.2em 'Lato', sans-serif !important;
}

.product-modal-wysiwig h4,
.product-modal-wysiwig h4 * {
    font: 32px/1.2em 'Lato', sans-serif !important;
}

.product-modal-wysiwig h5,
.product-modal-wysiwig h5 * {
    font: 28px/1.2em 'Lato', sans-serif !important;
}

.product-modal-wysiwig h6,
.product-modal-wysiwig h6 * {
    font: 24px/1.2em 'Lato', sans-serif !important;
}

.product-modal-wysiwig p {
    font: 14px/2em 'Lato', sans-serif !important;
    padding-bottom: 0;
    margin-bottom: 10px;
    width: 100%;
    clear: both;
    display: table;
}

.product-modal-wysiwig p * {
    font: 14px/2em 'Lato', sans-serif !important;
}

.product-modal-wysiwig iframe {
    margin: 0 auto;
    display: block;
}

.product-modal-wysiwig ol,
.product-modal-wysiwig ul {
    margin-bottom: 10px;
    font: 14px/2em 'Lato', sans-serif !important;
    list-style: none;
}

.product-modal-wysiwig ol {
    list-style: decimal;
    margin-left: 20px;
}

.product-modal-wysiwig ol li,
.product-modal-wysiwig ul li {
    margin-bottom: 20px;
    padding-left: 17px;
    clear: both;
    position: relative;
}

.product-modal-wysiwig ol li {
    padding-left: 10px;
}

.product-modal-wysiwig ol li:last-child,
.product-modal-wysiwig ul li:last-child {
    margin-bottom: 0;
}

.product-modal-wysiwig ul.checkbox_list li {
    padding-left: 0;
}

.product-modal-wysiwig ul li:before {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #cfd5d9;
    display: block;
    position: absolute;
    left: 0;
    top: 10px;
    content: '';
}

.product-modal-wysiwig table {
    margin: 0 auto 50px auto;
}

.product-modal-wysiwig table td {
    padding: 10px 20px;
    border: 1px solid #dfe5e4;
}

.product-modal-wysiwig table.white-table {
    font: 500 14px 'Lato', sans-serif !important;
    background: #fff;
    text-align: center;
    width: 100%;
    max-width: 520px;
    margin: 0 auto 20px auto;
    text-transform: uppercase;
}

.product-modal-wysiwig table th {
    border: 1px solid #e2e2e2;
    background: #dfe5e4;
    font: 500 16px/1.2em 'Lato', sans-serif !important;
    padding: 20px;
    text-align: left;
}

.product-modal-wysiwig table tr td:last-child {
    border-right: 0;
}

.product-modal-wysiwig a {
    color: #b4a2ca;
    border-bottom: 2px solid rgba(180, 162, 202, 0);
}

.product-modal-wysiwig a:hover {
    border-bottom: 2px solid rgba(180, 162, 202, 1);
}

.tiptap {
    min-height: 350px;  
    border: 1px solid #ccc;  
    overflow-y: auto;
  }